import request from "./request.js"
export function getRuleList(params) {//规则管理列表
    return request({
		url:"pool/chargeRule/list.do?",
        method: 'post',
        data: params
    })
}
export function addRule(params) {//规则管理添加
    return request({
		url:"pool/chargeRule/add.do?",
        method: 'post',
        data: params
    })
}
export function ruleDeleteById(params) {//规则管理删除
    return request({
		url:"pool/chargeRule/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function getCardPoolList(params) {//卡池管理列表
    return request({
		url:"pool/manage/list.do?",
        method: 'post',
        data: params
    })
}
export function changeStatus(params) {//激活流量池操作
    return request({
		url:"pool/manage/changeStatus.do?",
        method: 'post',
        data: params
    })
}
export function addCardPool(params) {//新增卡池管理
    return request({
		url:"pool/manage/add.do?",
        method: 'post',
        data: params
    })
}
export function editCardPool(params) {//编辑卡池管理
    return request({
		url:"pool/manage/update.do?",
        method: 'post',
        data: params
    })
}
export function cardNoExport(params) {//池卡管理导出
    return request({
        url: "pool/cardNo/export.do?",
        method: 'post',
        data: params
    })
}
export function cardNoAdd(params) {//池卡管理导卡入池
    return request({
        url: "pool/cardNo/add.do?",
        method: 'post',
        data: params
    })
}

export function cardNoList(params) {//池卡管理列表
    return request({
        url: "pool/cardNo/list.do?",
        method: 'post',
        data: params
    })
}
export function deleteCardNo(params) {//池卡管理删除功能
    return request({
        url: "pool/cardNo/deleteById.do?",
        method: 'post',
        data: params
    })
}



export function recordExport(params) {//流量池扣费记录导出
    return request({
        url: "pool/charge/record/export.do?",
        method: 'post',
        data: params
    })
}

export function recordList(params) {//流量池扣费记录列表
    return request({
        url: "pool/charge/record/list.do?",
        method: 'post',
        data: params
    })
}
export function cardUsageList(params) {//单卡用量列表
    return request({
        url: "pool/cardUsage/list.do?",
        method: 'post',
        data: params
    })
}
export function billExport(params) {//流量池历史用量导出
    return request({
        url: "pool/bill/export.do?",
        method: 'post',
        data: params
    })
}
export function billList(params) {//流量池历史用量列表
    return request({
        url: "pool/bill/list.do?",
        method: 'post',
        data: params
    })
}
export function alreadyPayBill(params) {//流量池历史用量上传凭据
    return request({
        url: "pool/bill/alreadyPay.do?",
        method: 'post',
        data: params
    })
}
export function batchUpdate(params) {//批量更改
    return request({
        url: "pool/cardNo/batchUpdate.do?",
        method: 'post',
        data: params
    })
}
export function updateRule(params) {//编辑扣费规则
    return request({
        url: "/pool/chargeRule/update.do?",
        method: 'post',
        data: params
    })
}
export function detailList(params) {//账单详情
    return request({
        url: "/pool/bill/detail.do?",
        method: 'post',
        data: params
    })
}

export function detailExport(params) {
    return request({
        url: '/pool/bill/detailExport.do?',
        method: 'post',
        data:params
    })
}














