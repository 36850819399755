<template>
    <div class="trafficPoolDetail fadeInRight">
        <div class="title-box clearfix">
            <span class="m-title">账单详情</span>
            <el-form inline ref="trafficPoolDetail" v-model="trafficPoolDetailData" class="right themed-form search-form" style="height: auto;line-height: 40px;">
              
                <el-form-item>
                    <el-input v-model="trafficPoolDetailData.iccid" placeholder="iccid" class="iccid-width" clearable></el-input>
                </el-form-item>
               
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">查询</el-button>
				</el-form-item>

                <el-form-item>
					<el-button icon="el-icon-download" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>
            </el-form>
        </div>

        <div class="content-box">
            <el-table
                border
                stripe
                ref="table"
                :max-height="maxTableHeight"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                >
                <el-table-column
                    prop="iccid"
                    label="ICCID"
                    >
                </el-table-column>
                <el-table-column
                    prop="billMonth"
                    label="账单月"
                    >
                </el-table-column>
                  <el-table-column
                    prop="accessNum"
                    label="接入号"
                    >
                </el-table-column>
                 <el-table-column
                    prop="usedFlow"
                    label="流量(M)"
                    >
                </el-table-column>
                 <el-table-column
                    prop="tradeMoney"
                    label="金额(元)"
                    >
                </el-table-column>
                <el-table-column
                    prop="agentName"
                    label="代理商"
                    >
                </el-table-column>
                <el-table-column
                    show-overflow-tooltip
                    label="付款类型"
                    >
                    <template slot-scope="scope">
                        <span>{{scope.row.payType=="1"?"预付扣费":scope.row.payType=="2"?"后付":"-"}}</span>
                    </template>
                </el-table-column>
                 <el-table-column
                    show-overflow-tooltip
                    label="交易类型"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.tradeType=='1'">入池</span>
                        <span v-else-if="scope.row.tradeType=='2'">池扣费</span>
                         <span v-else-if="scope.row.tradeType=='3'">单卡扣费</span>
                         <span v-else-if="scope.row.tradeType=='4'">停机保号费</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import resize from '@/mixins/resize.js'
import { detailList, detailExport} from "@/api/trafficPoolManagement.js";
export default {
    name:'deleteNetworkCardRecord',
    mixins:[resize],
    data(){
        return {
            trafficPoolDetailData:{
                iccid:'',
                id:""
            },
            tableData:[],
            page:1,
            pageSize:15,
            total:0,
        }
    },
    created(){
        this.trafficPoolDetailData.id = this.$route.query.id
        this.getTrafficPoolDetail()
    },
    methods:{
        getTrafficPoolDetail(){
            let params = {
                pageSize:this.pageSize,
                pageNo:this.page
            }
            Object.assign(this.trafficPoolDetailData,params)
            detailList(this.trafficPoolDetailData).then(res=>{
                this.tableData=res.rows
                this.total= res.count
            })
        },
        handleSizeChange(size){
            this.pageSize=size
            this.getTrafficPoolDetail()
        },
        onPageChange(page){
            this.page = page
            this.getTrafficPoolDetail()
        },
        onSearch(){
            this.page=1
            this.getTrafficPoolDetail()
        },
        exportExcel(){
            detailExport(this.trafficPoolDetailData).then(res=>{
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
						    confirmButtonText:'确定',
						}).then(()=>{
							this.$router.push('/tob/i_export_list')
						});
					}else{
						this.$message({
						    showClose: true,
						    message: res.system_result_message_key,
						    type: 'error'
						});
					}
				})
        },
        
    }
}
</script>
<style >
    
</style>